.locationMain {
    width: 100%;
}

.locationMain .locationInputPoint {
    width: 100%;
}



.locationMain .locationInputPoint input {
    width: 100%;
    padding: 5px;
}

.locationMain .locationInputPoint input:focus, .locationInput textarea:focus {
    outline: none;
}

.locationInput textarea {
    appearance: none !important;
    padding: 5px;
    margin-bottom: 5px;
    resize: none;
    width: 100%;
}

.locationMain .locationInput input {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
}

.locationInputfcol_6 {
    width: 60%;
}

.locationTitle {
    text-align: center;
    font-size: 22px;
    font-weight: 400;
}

input {
    width: 100%;
    padding: 5px;
    margin: 0;
}

h4 {
    margin: 20px 0;
}

.locationBorder {
    border: 1px solid #999;
    padding: 15px;
    background-color: #fff;
}

.PointofContact {
    position: relative;
    border: 1px solid #aaa;
    margin: 0 0 20px;
    padding: 15px 0;
}

.PointofCross {
    position: absolute;
    width: 30px;
    right: -10px;
    top: -14px;
    background-color: rgb(180, 2, 186);
    border: none;
    border-radius: 54px;
    height: 30px;
    display: grid;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 12px;
}

.plusIconSave {
    border-radius: 30px;
    padding: 0px 10px;
    line-height: 30px;
    font-size: 14px;
    align-self: center;
    margin-right: 10px;
    box-shadow: none;
}

.text-start-one {
    margin-left: -15px;
    text-align: left;
}

.saveBtnDiv {
    width: 100%;
    margin-top: 25px;
}

button.plusIconSave.savePadding {
    background: #b402ba;
    outline: none;
    border: none;
    color: #fff;
}

.savePadding {
    padding-left: 30px;
    padding-right: 30px;
}

.errorRed {
    color: red;
    position: relative;
    top: -9px;
    left: 0;
}