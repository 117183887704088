@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 100;
  src: url("font/fonts/lato-v22-latin-100.eot");
  src: local(""),
    url("font/fonts/lato-v22-latin-100.eot?#iefix") format("embedded-opentype"),
    url("font/fonts/lato-v22-latin-100.woff2") format("woff2"),
    url("font/fonts/lato-v22-latin-100.woff") format("woff"),
    url("font/fonts/lato-v22-latin-100.ttf") format("truetype"),
    url("font/fonts/lato-v22-latin-100.svg#Lato") format("svg");
}

/* lato-300 - latin */

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: url("font/fonts/lato-v22-latin-300.eot");
  src: local(""),
    url("font/fonts/lato-v22-latin-300.eot?#iefix") format("embedded-opentype"),
    url("font/fonts/lato-v22-latin-300.woff2") format("woff2"),
    url("font/fonts/lato-v22-latin-300.woff") format("woff"),
    url("font/fonts/lato-v22-latin-300.ttf") format("truetype"),
    url("font/fonts/lato-v22-latin-300.svg#Lato") format("svg");
}

/* lato-regular - 400 */

/* -------------------------------------- */

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("font/fonts/lato-v22-latin-regular.eot");
  src: local(""),
    url("font/fonts/lato-v22-latin-regular.eot?#iefix") format("embedded-opentype"),
    url("font/fonts/lato-v22-latin-regular.woff2") format("woff2"),
    url("font/fonts/lato-v22-latin-regular.woff") format("woff"),
    url("font/fonts/lato-v22-latin-regular.ttf") format("truetype"),
    url("font/fonts/lato-v22-latin-regular.svg#Lato") format("svg");
}

/* lato-700 - latin */

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("font/fonts/lato-v22-latin-700.eot");
  src: local(""),
    url("font/fonts/lato-v22-latin-700.eot?#iefix") format("embedded-opentype"),
    url("font/fonts/lato-v22-latin-700.woff2") format("woff2"),
    url("font/fonts/lato-v22-latin-700.woff") format("woff"),
    url("font/fonts/lato-v22-latin-700.ttf") format("truetype"),
    url("font/fonts/lato-v22-latin-700.svg#Lato") format("svg");
}


/* ------------------------------ */

#ChatDrop {
  max-height: 250px;
  overflow-y: auto;
  padding: 10px 0;
}

.SenterDocAnchor {
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 5px;
  gap: 7px;
  font-size: 13px;
  border-radius: 5px;
}

.SenterDocAnchor.right {
  color: #b402ba;
  border: 1px solid #b402ba;
}

.SenterDocAnchor.left {
  color: #7269ef;
  border: 1px solid #7269ef;
}

.SenterDocAnchor .ImageSenderSize {
  margin: 0;
  width: 36px;
  height: 36px !important;
}

img.sender,
video.sender {
  background: transparent;
  border: 1px solid #b402ba;
  padding: 5px;
}

img.receiver,
video.receiver {
  background: transparent;
  border: 1px solid #7269ef;
  padding: 5px;
}

tr.one-stap.itenary:hover {
  transform: scale(1.01);
  background: #f1d6f1;
  transition: 1s;
}

#popover-contained {
  transform: translate(366px, -92px) !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato";
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgb(107, 107, 107);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}

::-webkit-scrollbar-track {
  background: rgb(36, 36, 36);
}

::-webkit-scrollbar-corner {
  background: rgb(26, 26, 26);
}

::-webkit-resizer {
  background: rgb(26, 26, 26);
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
ol,
a,
span,
div {
  font-family: "Lato";
}

h1,
h2,
h4 {
  margin: 0;
}

p {
  margin: 0;
}

/* --------------------------------------------- */

.popover-body {
  padding: 0px 35px 0px 10px !important;
}

.selected {
  margin: 0 !important;
  cursor: pointer;
}

/* ---------------------------- */
body .btn-primary {
  background-color: rgb(180, 2, 186);
  border-color: rgb(180, 2, 186);
}

body .btn-primary:not(:disabled):not(.disabled).active:focus,
body .btn-primary:not(:disabled):not(.disabled):active:focus,
body .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(180, 2, 186, 0.5);
}

body .btn-primary:not(:disabled):not(.disabled).active,
body .btn-primary:not(:disabled):not(.disabled):active,
body .show>.btn-primary.dropdown-toggle {
  background-color: rgb(180, 2, 186);
  border-color: rgb(180, 2, 186);
}

body .btn-primary.hover,
body .btn-primary:hover {
  background-color: rgb(180, 2, 186);
  border-color: rgb(180, 2, 186);
  box-shadow: 0 0 0 0.2rem rgba(180, 2, 186, 0.5);
}

body .btn-primary.focus,
body .btn-primary:focus {
  background-color: rgb(180, 2, 186);
  border-color: rgb(180, 2, 186);
  box-shadow: 0 0 0 0.2rem rgba(180, 2, 186, 0.5);
}

body .form-check-input {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
  accent-color: #b402ba;
  cursor: pointer;
}

/* Globle Css End  */

header {
  background-color: #000000;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

header .navbar-brand img {
  max-height: 50px;
}


header .navbar-light .navbar-nav .nav-item {
  position: relative;
  display: flex;
  align-items: center;
  border-right: 1px solid #fff;
  margin-top: 5px;
  margin-bottom: 5px
}

header .navbar-light .navbar-nav .nav-item:first-child {
  border-left: 1px solid #fff;
}

header .navbar-light .navbar-nav .nav-item .nav-link {
  color: #ccc;
  transition: 0.4s ease;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
}

header .navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: #fff;
}

header .navbar-expand-lg {
  padding: 0 !important;
}

header .header-right {
  display: flex;
  width: 200px;
  justify-content: flex-end;
  align-items: center;
}

header .header-right .user-info {
  display: flex;
  margin-left: 15px;
}

header .header-right .search-box {
  display: flex;
  padding: 0;
}

.ul-check {
  position: absolute;
  inset: auto auto -15px 52px;
  margin: 0px;
  transform: translate3d(0px, -19px, 0px);
}

header .header-right .user-info .nav-item a.nav-link {
  padding: 0;
  display: flex;
}

header .header-right .user-info .nav-item a.nav-link {
  padding: 0;
  display: flex;
}

header .header-right .user-info .dropdown-menu {
  right: 0;
  left: auto;
  top: 55px;
  border: none;
  background: #000;
  border-radius: 0;
  color: #fff;
  padding: 0;
}

header .header-right .user-info .dropdown-menu .dropdown-item {
  color: #fff;
  padding: 10px 15px;
}

header .header-right .user-info .dropdown-menu .dropdown-item:hover {
  background-color: #b402ba;
}

header .header-right .user-info .dropdown-menu .dropdown-divider {
  margin: 0;
}

header .header-right .user-info .nav-item a.nav-link img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  padding: 2px;
  border: 1px solid #fff;
  border-radius: 50%;
  background: #000;
}

.container.Event-up {
  position: absolute;
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
}

header nav ul li.nav-item.active-now::after {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  left: calc(50% - 5px);
  z-index: 1;
  transform: rotate(0deg);
  transition: 0.2s ease;
}

header .header-right .search-box a {
  display: flex;
  padding: 0;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.search-head-icon {
  filter: invert(1);
  width: 100%;
  max-width: 30px;
  height: 100%;
  object-fit: contain;
}

.Main-all-project .nav-link {
  color: #000 !important;
}

.nav-link.active {
  color: #b900ba !important;
}

.serch-teb {
  display: none;
}

.search_btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #b900ba;
  box-shadow: none;
  border: none;
  width: 80px;
  height: 48px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}

.Serachbar+ul>div {
  padding-left: 0;
}

.checkbox-set label {
  width: calc(100% - 0px);
}

/* --------------MainPlanner---------------------- */
.MainPlannerImgAdd {
  display: flex;
  justify-content: center;
  text-align: center;
}

.searchbar-icon {
  width: 28px;
  height: 28px;
  filter: invert(1);
}

.head-inpu {
  width: 100%;
  margin: 0 auto 0px;
  max-width: 500px;
  display: flex;
  height: 48px;
  padding: 0px 20px 0 20px;
  padding-left: 35px;
  border: 1px solid #ddd;
  border-radius: 50px;
  box-shadow: 0 1px 4px #ddd;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: 10px 10px;
  cursor: pointer;
}

.head-inpu:focus {
  outline: none;
}

.Main-all-project {
  padding: 65px 0 0;
  height: calc(100vh - 50px);
  overflow: auto;
  background-repeat: no-repeat;
}

.Main-all-project .product-inner-area {
  position: relative;
}

.Main-all-project .product-inner-area .product-detail-all .product-inner-area {
  padding-top: 0 !important;
}

.Main-all-project .product-image {
  background-repeat: no-repeat;
  height: 200px;
  background-position: center -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}

.Main-all-project .container .search-area {
  padding-bottom: 30px;
}

.Main-all-project .container .product-headding {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 40%;
  margin: auto;
  padding-bottom: 30px;
}

.Main-all-project .container .product-headding .head-area-product {
  margin: 0 10px;
  padding: 10px 15px;
  border: 1px solid #bababa;
  background-color: transparent;
  border-radius: 20px;
  color: #070707;
  font-size: 14px;
  align-items: center;
  display: flex;
  opacity: 0.5;
  cursor: pointer;
  transition: 0.4s linear;
}

.Main-all-project .container .product-headding .head-area-product:hover {
  background: green;
  color: #fff;
  border: 1px solid green;
  box-shadow: 0 1px 4px gray;
}

.Main-all-project .product-inner-area .nav-tabs {
  border-bottom: none;
  padding: 30px 0;
}

.Main-all-project .container .product-detail-all {
  width: 100%;
  border-left: none;
  border-bottom: none;
  border-right: none;
  background: #fbf4fc;
  padding: 30px;
  border-radius: 0.25em;

}

.Main-all-project .container .product-detail-all-Iternary {
  width: 100%;
  border-left: none;
  border-bottom: none;
  border-right: none;
  background: #fbf4fc;
  padding: 0 30px 30px 30px;
  border-radius: 0.25em;

}

.Main-all-project .container .product-detail-all-doc {
  width: 100%;
  border-left: none;
  border-bottom: none;
  border-right: none;
  background: #fbf4fc;
  padding: 30px;
  border-radius: 0.25em;

}

.Main-all-project .container .product-detail-all-template {
  width: 100%;
  border-left: none;
  border-bottom: none;
  border-right: none;
  background: #fbf4fc;
  padding: 30px;
  border-radius: 0.25em;

}

.Main-all-project .container .product-detail-all-Activities {
  width: 100%;
  border-left: none;
  border-bottom: none;
  border-right: none;
  background: #fbf4fc;
  padding: 30px;
  border-radius: 0.25em;

}

.Main-all-project .container .product-detail-all-Plannerlatest {
  width: 100%;
  border-left: none;
  border-bottom: none;
  border-right: none;
  background: #fbf4fc;
  padding: 30px;
  border-radius: 0.25em;

}

.Main-all-project .container .product-detail-all-Eventslist {
  width: 100%;
  border-left: none;
  border-bottom: none;
  border-right: none;
  background: #fbf4fc;
  padding: 30px;
  border-radius: 0.25em;

}

.Main-all-project .container .product-detail-all-MainContainer {
  width: 100%;
  border-left: none;
  border-bottom: none;
  border-right: none;
  background: #fbf4fc;
  padding: 30px;
  border-radius: 0.25em;
}

.Main-all-project .container .product-detail-all .product-inner-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button:focus,
input:focus,
select:focus {
  outline: none !important;
}

.Main-all-project .container .product-detail-all .product-inner-area svg {
  filter: opacity(0.6);
}

.Main-all-project .product-inner-area .nav-tabs .nav-item .nav-link {
  border: none;
  text-transform: uppercase;
}

.Main-all-project .product-inner-area .nav-tabs .nav-item .nav-link.active {
  background: #b402ba;
  border-radius: 0.25rem;
  color: #fff !important;
}

footer p {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 0;
  font-weight: 400;
  height: 100%;
  text-align: center;
}

.tb-area {
  padding-bottom: 30px;
}

.outher-tb {
  display: flex;
  flex-direction: row;
}

.outher-tb li {
  padding: 0 15px;
}

.outher-tb li:hover a {
  color: rgb(3, 147, 200);
  border-bottom: 2px solid rgb(3, 147, 200);
}

.outher-tb li a {
  color: #000000;
  font-size: 16px;
  text-transform: uppercase;
}

.buton_vieMore {
  display: flex;
  width: 158px;
  height: 41px;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
  border: none;
  letter-spacing: 2px;
  background: #b402ba;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  transition: .4s ease;
}

.buton_vieMore:hover {
  background: #000;
}

.remove img {
  width: 30px;
  background: #b402ba;
  cursor: pointer;
  padding: 5px;
}

#navbarSupportedContent #navbarDropdown img {
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  object-fit: cover;
  padding: 5px;
  border-radius: 10px;
}

.tui-image-editor {
  top: 0px !important;
}

/* 20-05-2022 */

.row_main td.remove {
  border: 1px solid white;
  border-right: 1px solid #dee2e6;
}

/* 27-05-2022 */

.gallery_box {
  width: 100%;
  position: relative;
  padding-top: 25px !important;
  display: flex;
  border-top: 1px dashed rgba(0, 0, 0, 0.3);
  flex-wrap: wrap;
}

.gallery_box .gallery-img {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  height: 180px;
  padding: 5px;
}

.gallery_box .gallery-img div {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 10px;
  z-index: 1;
  position: relative;
}

.gallery_box .gallery-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.gallery_box .gallery-img span {
  position: absolute;
  top: 0;
  right: 20px;
  width: 25px;
  height: 25px;
  z-index: 2;
}

.gallery_box .gallery-img span a {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #000;
  cursor: pointer;
  padding: 2px;
}

.gallery_box .gallery-img span a:hover {
  color: #fff;
  text-decoration: none;
}

.gallery_box .gallery-img:hover span a,
.gallery_box .gallery_video:hover span a {
  background-color: #b402ba !important;
}

/* Img gallery End  */

.gallery_box .gallery_video {
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* overflow: hidden; */
  transition: 0.5s;
  height: 220px;
  padding: 5px;
}

.gallery_box .gallery_video div {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 10px;
  z-index: 1;
  position: relative;
}

.gallery_box .gallery_video iframe {
  height: 100%;
  width: 100%;
  border: none;
  background: #000;
}

.gallery_box .gallery_video span {
  position: absolute;
  top: -12px;
  right: 15px;
  width: 25px;
  height: 25px;
  z-index: 2;
}

.gallery_box .gallery_video span a {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #000;
  cursor: pointer;
  padding: 2px;
}

.gallery_box .gallery_video span a:hover {
  color: #fff;
  text-decoration: none;
}

.gallery_box .gallery_video:hover span a {
  background-color: red;
}

header .navbar-brand img {
  max-height: 35px;
  max-width: 120px;
  width: 100%;
}

header .navbar-light .navbar-nav .nav-item:first-child {
  border: none;
}

header .navbar-light .navbar-nav .nav-item {
  border: none !important;
}


.Main-all-project .container .product-detail-all .product-inner-area svg {
  right: -12px !important;
  top: -4px !important;
}

.form-group.row.d-flex.justify-content-around.addDel {
  border: 1px solid #000;
  padding: 10px 0;
}

.gallery_box .gallery_docs {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  height: 180px;
  padding: 5px;
}

.gallery_box .gallery_docs div figure {
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 10px;
}

.gallery_box .gallery_docs div figure a {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.gallery_box .gallery_docs div figure a img {
  width: auto;
  height: 100%;
  object-fit: contain;
  filter: opacity(0.6);
}

.gallery_box .gallery_docs div .name {
  background: #666;
  height: 40px;
  display: flex;
}

.gallery_box .gallery_docs div .name a {
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding: 10px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gallery_box .gallery_docs div .name a img {
  margin-right: 10px;
}

.gallery_box .gallery_docs span {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  z-index: 2;
}

.gallery_box .gallery_docs span a {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #000;
  cursor: pointer;
  padding: 2px;
}

.gallery_box .gallery_docs span a:hover {
  color: #fff;
  text-decoration: none;
}

.gallery_box .gallery_docs:hover span a {
  background-color: red;
}

/* Gallery End */

.tables.table-bordered {
  max-width: 100%;
}

.d-fex-center {
  width: 40%;
  height: 100%;
  display: flex;
  margin: 100px auto;
  background: black;
  align-items: center;
  justify-content: center;
}

.d-feex-center {
  display: flex;
  margin: 100px auto;
  align-items: center;
  justify-content: center;
}

.d-feex-center h3 {
  background: #b402ba;
  padding: 30px;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}

.d-feex-center.red h3 {
  background: red;
}

.event-listing-row {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}

.event-listing-row:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.event-listing-row span {
  padding-right: 0;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
}

.event-listing-row span:first-child {
  padding-left: 0;
}

.event-listing-row span:last-child::after {
  display: none;
}

.event-listing-row:hover span {
  color: #b402ba;
}

.event-listing-row span.lable {
  text-transform: capitalize;
  font-weight: 600;
}

.event-listing-row span::after {
  content: "";
  width: 5px;
  height: 5px;
  background: #999;
  border-radius: 10px;
  margin: 0 10px;
}

.Main-all-project .container .product-detail-all .product-inner-area .eventLinkBiCopy {
  position: relative;
}

.Main-all-project .product-detail-all .form-col .eventLinkBiCopy svg {
  position: absolute !important;
  top: 11px !important;
  right: 6px !important;
}

/* Form Css */

body .form-wrapper .form-label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
  font-family: "Lato";
  display: block;
}

body .form-wrapper .form-col {
  padding-bottom: 20px;
  position: relative;
}

body .form-wrapper .form-control {
  display: block;
  width: 100%;
  /* height: calc(2em + 0.75rem + 2px); */
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #444;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 5px;
}

body .form-wrapper textarea.form-control {
  height: 100px;
}

body .form-wrapper .form-control:disabled,
body .form-wrapper .form-control[readonly] {
  background-color: #fdfafd;
  opacity: 1;
  color: #222;
}

body .form-wrapper .form-control:focus-visible {
  outline: none;
}

body .form-wrapper .form-control:focus {
  color: #222;
  background-color: #fff;
  border-color: rgba(180, 2, 186, 0.2);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(180, 2, 186, 0.5);
}

.form-wrapper .row-btn .btn {
  padding: 0.75em 2em;
  min-width: 120px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.025em;
}

.tui-image-editor {
  width: 100%;
}

.tui-image-editor-container {
  width: 100% !important;
}

body .tui-image-editor-container .tui-image-editor-controls {
  height: auto;
  display: flex;
  align-items: center;
}

.Main-all-project .product-inner-area .upload-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.Main-all-project .product-inner-area .upload-panel .file-tabs {
  display: flex;
  align-items: center;
  width: 100%;
}

.Main-all-project .product-inner-area .upload-panel .file-tabs .nav-item {
  flex-grow: 1;
  text-align: center;
}

.Main-all-project .product-inner-area .upload-panel .file-tabs .nav-item .nav-link {
  border-radius: 0;
  padding-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.Main-all-project .product-inner-area .upload-panel .file-tabs .nav-item .nav-link.active {
  background-color: transparent;
  color: #b402ba !important;
  font-weight: 700;
  border-color: #b402ba;
}

.Main-all-project .product-inner-area .upload-panel .tab-content .product-detail-all {
  padding: 0;
}

.Main-all-project .product-inner-area .upload-panel .tab-content .product-detail-all .file-upload-inner {
  width: 100%;
  background: #fff;
  border: 1px dashed rgba(0, 0, 0, 0.3);
}

.Main-all-project .product-inner-area .upload-panel .tab-content .product-detail-all .file-upload-inner .file-upload .tab-pane {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Main-all-project .product-inner-area .upload-panel .tab-content .product-detail-all .file-upload-inner .file-upload .tab-pane h1 {
  font-size: 18px;
  margin: 0;
  flex-grow: 0;
  font-weight: bold;
}

.Main-all-project .product-inner-area .upload-panel .tab-content .product-detail-all .file-upload-inner .file-upload .tab-pane .dropzone {
  margin: 0;
  flex-grow: 2;
  padding: 0 15px;
}

.Main-all-project .product-inner-area .upload-panel .tab-content .product-detail-all .file-upload-inner .file-upload .tab-pane .row-btn {
  margin: 0 !important;
  flex-grow: 0;
}

.Main-all-project .product-inner-area .upload-panel .tab-content .product-detail-all .file-upload-inner .file-upload .tab-pane .dropzone .upload_image_input {
  width: 100%;
  display: flex;
  align-items: center;
}

.Main-all-project .product-inner-area .upload-panel .tab-content .product-detail-all .file-upload-inner .file-upload .tab-pane .dropzone .upload_image_input input[type="file"] {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px dashed rgba(0, 0, 0, 0.3);
  padding: 10px;
  background: rgba(0, 0, 0, 0.05);
  font-size: 14px;
  color: #666;
  cursor: pointer;
}

.Main-all-project .product-inner-area .upload-panel .tab-content .product-detail-all .file-upload-inner .file-upload .tab-pane .dropzone .upload_image_input label {
  display: none;
}

/* Form CSS End */

/* itinerary Table */

.itinerary-box {
  width: 100%;
  position: relative;
}

.itinerary-box .table-responsive {
  height: 450px;
  margin-bottom: 30px;
}

.itinerary-box .row-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.itinerary-box .table-responsive table {
  border-spacing: 0;
  width: 100%;
}

.itinerary-box .table-responsive thead {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.itinerary-box .table-responsive tbody {
  font-size: 14px;
}

.itinerary-box .table-responsive tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.itinerary-box .table-responsive th,
.itinerary-box .table-responsive td {
  padding: 0;
  white-space: nowrap;
}

.itinerary-box .table-responsive tr td {
  border-right: 1px solid #d4bdd2;
}

.itinerary-box .table-responsive tr td:nth-child(2) {
  max-width: 80px;
}

.itinerary-box .table-responsive td input {
  height: 30px;
  padding: 3px;
  font-size: 12px;
  border: none;
  width: 100%;
}

.itinerary-box .table-responsive td input:hover {
  background-color: #fbf4fc;
}

.itinerary-box .table-responsive td input:focus,
.itinerary-box .table-responsive td input:focus-visible {
  outline: none;
  box-shadow: none;
  border: none;
}

.itinerary-box .table-responsive thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: transparent;
}

.itinerary-box .table-responsive thead th {
  padding: 7px;
  background-color: #b402ba;
  color: #fff;
  box-shadow: 0 0 1px #fff;
  text-align: center;
  text-transform: capitalize;
}

.itinerary-box .table-responsive th:first-child,
.itinerary-box .table-responsive td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: #b402ba;
  color: #fff;
  padding: 0;
  z-index: 1;
  box-shadow: 0 0 1px #fff;
}

.itinerary-box .table-responsive td:first-child {
  min-width: 30px;
  width: 30px;
  max-width: 30px;
}

/* itinerary Table */

.chat-sheat {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat-sheat .container {
  max-width: 100%;
  width: 100%;
  padding: 0;
}

.chat-sheat .container:first-child h4 {
  margin-top: 0;
  font-weight: bold;
}

.chat-sheat hr {
  display: none;
}

.page-title {
  padding: 30px;
  background: #f7f7f7;
  text-align: center;
}

.product-inner-area .product-inner-area.profile_img-are {
  padding-top: 0;
  margin-bottom: 30px;
}

.product-inner-area .product-inner-area.profile_img-are input {
  height: 0;
}

.product-inner-area .profile_img-are .profile_img_round {
  width: 220px;
  height: 220px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  padding: 5px;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.2);
}

.product-inner-area .profile_img-are .profile_img_round img {
  padding: 5px;
  border-radius: 100%;
  border: 2px solid #333;
}

.boxee {
  position: absolute;
  cursor: move;
  color: black;
  max-width: 215px;
  border-radius: 5px;
  padding: 1em;
  margin: auto;
  user-select: none;
}

.product-inner-area .profile_img-are .profile_img_round .edit_halwa {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
  visibility: hidden;
}

.product-inner-area .profile_img-are .profile_img_round:hover .edit_halwa {
  visibility: visible;
}

.product-inner-area .profile_img-are .profile_img_round .edit_halwa img {
  background: transparent;
  object-fit: contain;
  padding: 5px;
  border-radius: 0;
  width: 50px;
  height: 50px;
  border: none;
}

.Chat-login-main {
  padding: 50px;
  width: 50%;
  margin: auto;
  margin-top: 80px;
}

.demo0 img {
  margin: auto;
  display: block;
}

.Serachbar {
  position: relative;
  max-width: 500px;
  margin: 0 auto 30px;
}

.accordion-body .Serachbar {
  margin: 15px 10px;
}

.accordion-body .head-inpu {
  height: 38px;
}

.accordion-body .search_btn .searchbar-icon {
  filter: opacity(0.5);
  width: 24px;
  height: 24px;
}

.accordion-body .head-inpu:focus {
  position: relative;
  z-index: 1;
}

.accordion-body .search_btn {
  background: transparent;
  left: 0;
  width: 46px;
  height: 45px;
}

.accordion-body .search_btn .searchbar-icon {
  filter: opacity(0.5);
}

/* checkbox css */

.checkbox-set label::after {
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  position: absolute;
  left: 0;
  top: 0;
}

.checkbox-set label::before {
  content: "";
  display: block;
  width: 7px;
  height: 14px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  left: 7px;
  top: 1px;
  opacity: 0;
}

.checkbox-set label {
  position: relative;
  width: calc(100% - 20px);
  padding-left: 40px;
}

.checkbox-set [type="checkbox"] {
  opacity: 0;
  position: absolute;
  left: 18px;
  top: 3px;
  z-index: 1;
  cursor: pointer;
}

.checkbox-set [type="checkbox"]:checked+label::before {
  opacity: 1;
}

.addbutton {
  margin-top: 30px;
  text-align: center;
}

.wholeScreen {
  height: 100vh;
  width: 100vw;
}

.time {
  font-size: 10px;
  color: #070707;
}

.accordion_right_content_box .usser_title {
  text-align: center;
}

.spinner_design div {
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #000000ad;
  z-index: 999999;
}



.msg {
  width: fit-content;
  height: fit-content;
  background-color: #764cef;
  border-radius: 5px;
  color: white;
  padding-inline: 10px;
}

.sender {
  background-color: #b402ba;
  color: #fff;
  padding: 10px;
  position: relative;
  margin: 10px 10px 0 auto;
  border-radius: 10px 0 10px 10px;
  height: auto;
  text-align: left;
  font-size: 14px;
  max-width: 50%;
  word-break: break-word;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.5;
}

.inputbox {
  display: flex;
  width: 100%;
  position: fixed;
  bottom: 0;
  background: #fff;
  padding: 10px 20px;
  max-width: 1170px;
}

.App.chatdisplay {
  max-width: 1170px;
  width: 100%;
  margin: auto;
  padding: 50px 0;
}

.receiver small {
  font-size: 12px;
}

.receiver {
  background-color: #7269ef;
  color: #fff;
  padding: 10px;
  position: relative;
  margin: 10px auto 0 10px;
  border-radius: 0px 10px 10px 10px;
  height: auto;
  font-size: 14px;
  max-width: 50%;
  word-break: break-word;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.5;
}

.receiver>p {
  margin: 0;
}

.receiver::before {
  border-bottom: 5px solid transparent;
  border-left: 5px solid #7269ef;
  border-left-color: #7269ef;
  border-right: 5px solid transparent;
  border-top: 5px solid #7269ef;
  border-top-color: #7269ef;
  top: 0;
  content: "";
  left: -8px;
  position: absolute;
  right: auto;
  transform: rotate(95deg);
}

.Main-all-project .accordion-button {
  font-weight: bold;
}

.accordion-body .chathvover {
  color: black;
  text-decoration: none;
  text-transform: capitalize;
}

.accordion_user {
  padding: 7px 1.5rem;
  transition: all 0.4s ease-in-out;
  font-size: 14px;
  border-bottom: 1px solid rgb(180 2 186 / 30%);
  border-radius: 0;
  position: relative;
}

.no-data {
  display: flex;
  justify-content: center;
  text-align: center;
}

span.dot-open {
  width: 18px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: #b402ba;
  position: absolute;
  text-align: center;
  right: 16px;
  color: white;
  border-radius: 50px;
}

.accordion_user a {
  text-decoration: none;
}

.accordion_user.colored {
  background-color: #b402ba;
}

.accordion_user.colored>a {
  color: #fff;
}

.accordion_left_two .accordion_user {
  text-decoration: none;
}

.Main-all-project .accordion-body {
  padding: 0;
}

.Main-all-project .accordion_right_content {
  width: auto;
  display: inline-block;
}

.Main-all-project .accordion_right_content_box {
  width: calc(100% - 288px);
  height: calc(100vh - 126px);
  position: relative;
}

.Main-all-project .accordion_left .accordion_user a.chathvover {
  display: block;
  text-transform: capitalize;
}

.Main-all-project .usser_title {
  display: flex;
  padding: 10px;
  box-shadow: 0px 0 10px 0px rgb(180 2 186 / 30%);
}

.Main-all-project .usser_title h3 {
  width: calc(100% - 183px);
  margin: 0px;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  margin-top: 3px;
}

.sender,
.receiver {
  font-size: 13px;
}

.Main-all-project .accordion_right_content_box .sender::before {
  border-bottom: 5px solid transparent;
  border-left: 5px solid #bbb7b7;
  border-left-color: #b402ba;
  border-right: 5px solid transparent;
  border-top: 5px solid #bbb7b7;
  border-top-color: #b402ba;
  top: 0;
  content: "";
  left: auto;
  position: absolute;
  right: -10px;
}

.Main-all-project .accordion_right_content_box .inputbox {
  display: flex;
  margin-left: 5px;
  width: calc(100% - 300px);
  position: fixed;
  bottom: 55px;
  background: #fff;
  padding: 8px;
  max-width: 100%;
  align-items: center;
  box-shadow: 0px 0 10px 0px rgb(180 2 186 / 30%);
}

.sender_img {
  text-align: right;
}

.Main-all-project .accordion-item {
  border: none;
}

.Main-all-project .accordion-button {
  color: #ffffff;
  background: rgba(180, 2, 186, 1);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  font-weight: bold;
  padding: 8px 12px 11px 17px;
  border: 1px solid rgba(180, 2, 186, 1);
}

.Main-all-project .accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.chat_info {
  width: 100%;
  overflow-y: auto;
  height: calc(100% - 111px);
  padding: 5px;
  position: relative;
}

.Main-all-project .accordion_right_content_box .inputbox button.btn.file_btn {
  border: 1px solid lightgray;
  margin: 0 10px !important;
}

.Main-all-project.chats {
  display: flex;
  flex-direction: row;
}

/* Docdoc page css */

.docdoc_main {
  width: 100%;
  overflow-x: auto;
  text-align: center;
}

ul.one-stap {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  overflow-x: auto;
  /* padding: 0; */
  min-width: 950px;
}

ul.one-stap li:nth-child(1) {
  width: 250px;
}

ul.one-stap li:nth-child(2) {
  width: 300px;
}

ul.one-stap li:nth-child(3) {
  width: 250px;
}

ul.one-stap li:nth-child(4) {
  width: 15%;
}

.kuchBhi_cancel {
  color: black;
  font-size: 24px;
}

.gallery_box.doc_image.grid-items {
  flex-direction: column;
  padding: 0;
}

.waves-light.doc_btn {
  max-width: 200px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

#participant ul.row {
  list-style: none;
  padding: 0 15px;
}

.addbutton>.save {
  max-width: 119px;
  width: 100%;
}

video.dragHandle {
  width: 100% !important;
  height: auto !important;
}

.nav-pills .nav-link {
  border: 2px solid #fbf4fb;
  padding: 5px;
  width: 120px;
}

#collapseTwo {
  height: calc(100vh - 166px);
  background: #f7f4f4;
  overflow: auto;
}

.main-doom {
  position: absolute;
  width: calc(100% - 286px);
  bottom: 60px;
}

.dhoom {
  background: #b402ba;
  color: #fff;
}

.receiver_video {
  background-color: #7269ef;
  padding: 10px;
  position: relative;
  margin: 10px 10px 0 auto;
  border-radius: 10px;
  border-top-left-radius: 0;
  font-size: 14px;
  max-width: 80%;
  word-break: break-word;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1.5;
  height: 200px;
}

footer {
  width: 100%;
  height: 50px;
  background-color: #b402ba;
  display: flex !important;
  justify-content: center;
  align-items: inherit;
  position: relative;
}

.welcome-img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.error_image {
  height: 60vh;
  width: 100%;
  position: relative;
  object-fit: contain;
  filter: grayscale(1);
}

.error_images {
  height: 60vh;
  width: 100%;
  position: relative;
  object-fit: contain;
}

.something {
  text-align: center;
}

span.badge.bg-secondary {
  background-color: #6c757d !important;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 27px;
  margin-left: 10px;
}

.Main-all-project .product-inner-area .upload-panel .file-tabs {
  padding-top: 0;
}

.nav-pills .nav-link>span {
  font-size: 14px;
  margin-top: 5px;
  line-height: normal;
}

.chat_Valiclass svg.chatIcon {
  height: 25px;
  width: 25px;
}

.chat_Valiclass a {
  color: #ffffff;
}

.chat_Valiclass {
  position: relative;
}

span.badge.bg-secondary {
  height: 12px;
  width: 12px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 27px;
  margin-left: 17px;
  background-color: #3cc33c !important;
  position: absolute;
  top: -55%;
  right: 4px;
}

.chat_Valiclass a:hover {
  color: #ffffff;
}

.Main-all-project .product-inner-area div#test {
  background: #b402ba;
  color: #fff;
  z-index: 5555555;
}

.Main-all-project .product-inner-area button.btn {
  border-radius: 30px;
  padding: 0px 10px;
  line-height: 30px;
  font-size: 14px;
  align-self: center;
  margin-right: 10px;
  box-shadow: none;
}

tr.one-stap.itenary td input {
  background: #fff;
  border: 1px solid #000;
  padding: 5px 10px;
}

.plusIcon {
  padding: 0px 10px;
  line-height: 30px;
  font-size: 14px;
  align-self: center;
  margin-right: 6px;
  box-shadow: none;
  border: 0.5px solid #b402ba;
  color: #fff;
  background-color: #b402ba;

}

.Main-all-project .product-inner-area .participentBtnDiv .btn {
  padding: 0px 30px;
}

.Main-all-project .product-inner-area .form-label div#test.place-top::after {
  border-top-color: #b402ba !important;
}

.downloadlastbutton .btn {
  margin-top: 20px;
}

.inner_striped tr.one-stap.dragHandle.item.child:nth-child(2n+3) {
  background: #fff;
}

body .table div.downloadlastbutton {
  border: none;
}

tr.one-stap {
  display: flex;
  justify-content: space-around;
}

.table td, .table th {
  padding: 0;
  border: 0;
}

table.table.table-striped.gallery_box.Maintable_striped {
  display: flex;
  justify-content: center;
  width: 100%;
}

.inner_striped {
  width: 100%;
}

tr.one-stap {
  width: 100%;
}

.td-fileName {
  word-break: break-all;
}

.one-stap th, td {
  text-align: center;
}

table tr:nth-child(even) {
  background-color: #dddddd;
}

.itenary select {
  word-wrap: normal;
  width: 100%;
  padding: 6px;

}


.itenary input {
  padding: 2px;
  height: 36px;
  width: 100%;
}

.itinerary-box textarea {
  height: 36px;
  width: 100%;
}



.plannerCross {
  filter: opacity(0.6);
  background: #b402ba;
  color: #fff;
  padding: 3px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}


.Main-all-project .container .product-detail-all-Iternary .product-inner-area svg {
  filter: opacity(0.6);
  background: #b402ba;
  color: #fff;
  padding: 3px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.form-group.row.d-flex.justify-content-around.addDel {
  position: relative;
}

.CrossBtn {
  width: auto;
  border: none;
  background: #fbf4fc;
}

.crossMain {
  position: absolute;
  right: -44px;
  top: -25px;
  font-size: 11px !important;
}


.crossMain_Span {
  position: absolute !important;
  right: 23px;
  top: -1px;
}

.subChildCrossBtn {
  font-size: 11px;

}

.subchildDelete {

  width: auto;
  margin-top: 31px;
}

.subchildDeletebtn {
  padding: 0px 10px;
  line-height: 30px;
  font-size: 11px;
  align-self: center;
  margin-right: 6px;
  box-shadow: none;
  border: 0.5px solid #b402ba;
  color: #fff;
  background-color: #b402ba;
  border-radius: 50%;
}

.subChildCross {
  position: absolute;
  right: 170px;
  top: 50px;
}

.CrossBtn svg {
  width: 27px;
  height: 28px;
  background: #b402ba;
  padding: 4px;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -10px;
  color: #fff;
}

.participent-modalbox .modal {
  top: 30px;
}

.participent-modalbox .modal-content li {
  list-style: none;
  text-align: start;
  justify-content: space-between;
}

.addbutton {
  margin-top: 30px;
  text-align: start;
}

.addbutton button {
  font-size: 16px;
}


.itinerary-box .itenary span {
  position: absolute;
  right: -10px;
  margin-top: -30px;
  width: 14px;
  height: 14px;
  margin-right: 14px;
}

.deleteSpan {
  justify-content: end;
  display: flex;
  align-items: center;
  max-width: 5% !important;
}

.addDel select#exampleFormControlSelect2 {
  padding: 8px;
}

.addDel input.col-md-5 {
  padding: 8px;
}

.itinerary-box .one-stap th {
  border: none !important;
}

.allInputsGroup {
  max-width: 800px !important;
}

.allInputsGroup .col-md-3 {
  max-width: 23%;
  flex: 0 0 23%;
  margin: 2px;
}

li.draggable.item {
  border-top: 1px solid #eee !important;
  padding: 0;
}

.addDel {
  margin-bottom: 6px !important;
}

.itinerary-box .itenary {
  position: relative;
  padding: 20px 0;
  width: 100%;
}

.itinerary-box .itenary td {
  border-top: none;
}

.itinerary-box .CustomeInput {
  width: 100%;
  margin-top: 8px;
}

.UploadItenaryInput {
  border: 1px solid #000;
  background-color: #fff;
  max-width: 100%;
  width: 100%;
  text-align: center;
}

.itenary td a {
  width: 100%;
  border: 1px solid black;
  background-color: white;
  display: block;
  margin-top: 8px;
  padding: 6px;
  text-decoration: none;
  color: black;
}

tr.one-stap {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.chageColorChild {
  padding: 35px 10px;
  max-height: 70px;
  height: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.chageColorChild:nth-child(odd) {
  background: #fbf4fc;
}

.chageColorChild:nth-child(even) {
  background: #ffffff;
}

/* 20-12-2022 -planer section css start */
.plannerMain {
  border: 1px solid #999;
  padding: 15px;
  background-color: #ffffff;
}

input[type=time] {
  cursor: pointer;
}

input.allinput-inner {
  margin: 0 5px 5px 0;
  width: max-content;
}

.plannerSubchild {
  padding: 15px 10px;
  border: 1px solid #999;
  margin: 0 0 10px 0;
  position: relative;
}

.plannerSubchild .form-group {
  margin-bottom: 0;
}

.plannerSubchild .subchildDelete {
  position: absolute;
  top: -20px;
  right: -20px;
  left: auto;
  margin: 0;
}

.ChildPlanner_span {
  margin-left: -12px;
}

.planner_subChild_border button.btn.btn-primary.my-2.crossMain {
  margin-right: 30px;
}

.planner_subChild_border {
  border: 1px solid #999;
  padding: 15px;
}

.plannerMain input {
  padding: 5px;
  width: 100%;
}

.title_planner {
  text-align: center;
  font-weight: 700;
}

.title_planner_child {
  text-align: center;
  font-weight: 600;
}

.Main-all-project .product-inner-area .nav-tabs .nav-item .nav-link.active svg>path {
  stroke: #fff;
}

.Indoor_Outdoor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid gray;
  padding: 6px;
  background: #fff;
}


.Indoor_Outdoor input {
  accent-color: #b402ba;
  width: 22px !important;
  height: 16px;
}

.allInputs {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 15px;
}

.backgroundRoom {
  background-color: #9d799f;
  margin-top: 15px !important;
}

/* planer section css end*/







/*****************************
Responsive CSS ***************
******************************/

@media (max-width: 1440px) {
  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
  }

}

@media (max-width: 1200px) {
  header .header-right {
    width: auto;
    margin-left: auto;
  }

  header .navbar-expand-lg .navbar-collapse {
    /* flex-grow: inherit; */
    margin-left: 30px;
  }

}

@media (max-width: 991px) {

  /* container */
  body .container,
  body.container-md,
  body .container-sm {
    max-width: 100%;
  }

  /* container */
  header .navbar-expand-lg {
    padding: 7px 0 !important;
    flex-wrap: nowrap;
  }

  header .navbar-expand-lg .navbar-toggler {
    order: 3;
    padding: 5px;
    background: #b402ba;
    margin-left: 25px;
  }

  header .navbar-expand-lg .navbar-toggler .navbar-toggler-icon {
    filter: invert(1);
  }

  header .header-right .user-info .nav-item a.nav-link img {
    width: 42px;
    height: 42px;
  }

  header .header-right .search-box a {
    width: 38px;
    height: 38px;
  }

  header .navbar-expand-lg .navbar-collapse {
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    width: 100%;
    background: #000;
    border-top: 1px solid #ccc;
  }

  header .navbar-light .navbar-nav .nav-item {
    align-items: initial;
  }

  header .navbar-light .navbar-nav .nav-item .nav-link {
    padding: 12px 15px;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #fff;
    font-size: 14px;
    text-align: left;
    width: 100%;
  }

  header .navbar-light .navbar-nav .nav-item .nav-link:hover,
  header .navbar-light .navbar-nav .nav-item.active-now .nav-link {
    background-color: #b402ba;
  }

  .Main-all-project .container .product-headding {
    max-width: 75%;
  }

  .event-listing-row span {
    font-size: 16px;
  }

  .event-listing-row span.date,
  .event-listing-row span.time {
    font-size: 14px;
  }

  .event-listing-row span.date:after {
    display: flex;
  }

  body .form-wrapper .form-col {
    padding-bottom: 10px;
  }

  .Main-all-project .product-inner-area .nav-tabs .nav-item .nav-link {
    border: none;
    font-size: 14px;
    padding: 10px;
  }
}

@media (min-width: 768px) {
  #accordionExample {
    width: 288px;
    border-right: 1px dotted #aaa;
  }

  .Main-all-project .accordion-button {
    height: 58px;
  }

  .Serachbar+ul>div {
    padding-left: 0;
  }

  .checkbox-set label {
    width: calc(100% - 0px);
  }
}

@media (max-width: 767px) {
  header .navbar-expand-lg {
    padding: 10px 0 !important;
  }

  .itinerary-box .itenary {
    padding: 10px 0;
  }

  .table>div {
    min-width: 500px;
  }

  .itinerary-box .itenary td {
    border-top: none;
    padding: 5px 8px;
  }

  .table svg {
    fill: #b402ba;
    filter: opacity(1) !important;
  }

  .itinerary-box .one-stap th {
    font-size: 12px;
    white-space: nowrap;
  }

  .allInputs, .d-flex.addDel {
    flex-wrap: wrap;
    padding: 0 !important;
  }

  .plannerMain input {
    margin: 0 0 10px !important;
  }

  .w-50.mr-1 {
    width: 100% !important;
  }

  .Main-all-project .product-inner-area button.btn, .subchildDeletebtn {
    padding: 0px 5px;
    line-height: 22px;
  }

  .col-md-6.pl-0 {
    padding-right: 0;
  }

  #ChatDrop {
    transform: translate3d(40%, -16px, 0px) !important;
  }

  header .navbar-brand img {
    max-height: 35px;
  }

  header .header-right .search-box a {
    width: 32px;
    height: 32px;
  }

  header .header-right .user-info .nav-item a.nav-link img {
    width: 32px;
    height: 32px;
  }

  header .navbar-expand-lg .navbar-toggler {
    margin-left: 15px;
  }

  header .navbar-expand-lg .navbar-toggler {
    padding: 3px;
    margin-left: 15px;
  }

  header .navbar-expand-lg .navbar-toggler .navbar-toggler-icon {
    width: 30px;
    height: 30px;
  }

  header .header-right .user-info .dropdown-menu {
    top: 45px;
  }

  .Main-all-project .container .product-headding {
    max-width: 100%;
    flex-wrap: wrap;
    grid-row-gap: 10px;
  }

  .product-inner-area .profile_img-are .profile_img_round {
    width: 150px;
    height: 150px;
  }

  body .form-wrapper .form-control {
    height: calc(1.4em + 0.75rem + 2px);
    margin-bottom: 8px;
  }

  .Main-all-project {
    padding: 70px 0 0px;
    min-height: calc(100vh - 50px);
  }

  .Main-all-project .container .search-area {
    padding: 30px 15px;
  }

  .Main-all-project .product-inner-area .nav-tabs .nav-item {
    flex-grow: 1;
    text-align: center;
  }

  .Main-all-project .product-inner-area .nav-tabs .nav-item .nav-link {
    font-size: 24px;
    padding: 10px;
    line-height: 1;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gallery_box .gallery-img {
    width: 50%;
    height: 30vw;
  }

  .gallery_box .gallery_video {
    width: 50%;
    height: auto;
  }

  .gallery_box .gallery_docs {
    width: 100%;
    height: 45vw;
  }

  .itinerary-box .table-responsive td {
    min-width: 160px;
  }

  .itinerary-box .table-responsive tr td:nth-child(2) {
    max-width: 80px;
    min-width: 80px;
  }

  /* chatbox css */
  .Main-all-project .accordion_right_content_box {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 66px;

  }

  .main-doom {
    bottom: 182px;
  }

  img.receiver,
  video.receiver,
  img.sender,
  video.sender {
    height: 100px !important;
  }

  .Main-all-project.chats {
    flex-direction: column;
    height: calc(100vh - 100px);
  }

  .Main-all-project .accordion-item {
    width: 100%;
  }

  .accordion-collapse {
    position: absolute;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    left: 0;
  }

  .Main-all-project .accordion_right_content_box .inputbox {
    margin-left: 0;
    max-width: 100%;
    width: 100%;
  }

  .accordion-collapse {
    position: absolute;
    background-color: #fff;
    z-index: 9;
    left: 0;
    width: 100vw;
  }

  .Main-all-project .accordion {
    max-width: 50px;
    margin-top: -5px;
    position: fixed;
    display: flex;
    border-right: none;
    width: 100%;
    z-index: 555;
  }

  .Main-all-project .usser_title {
    padding-left: 50px;
  }

  .Main-all-project .usser_title h3 {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 35px);
  }

  .usser_title button {
    width: 35px;
    background: transparent;
    border: none;
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    padding: 10px 10px 10px 16px;
    height: 45px;
  }

  .event-listing-row span.date,
  .event-listing-row span.time,
  .event-listing-row span {
    font-size: 12px;
  }

  footer p {
    font-size: 12px;
  }

  .Main-all-project .container .product-detail-all {
    padding: 15px;
  }

  .form-wrapper .row-btn .btn {
    padding: 0.35em 2em;
    min-width: 80px;
    font-size: 16px;
  }

  body .form-wrapper .form-col {
    margin-top: 0;
  }

  .form-btn-area {
    text-align: center;
  }

  .Main-all-project .product-inner-area .upload-panel .tab-content .product-detail-all .file-upload-inner .file-upload .tab-pane {
    flex-direction: column;
    gap: 12px;
  }

  .dragHandle .one-stap li,
  ul.one-stap li {
    font-size: 14px;
  }

  .chat_info {
    height: calc(100% - 150px);
  }

  ul.one-stap li:nth-child(1) {
    width: 200px;
  }

  ul.one-stap li:nth-child(1) {
    width: 200px;
  }

  #collapseTwo {
    height: calc(100vh - 160px);
  }

  #popover-contained {
    inset: auto auto 38px 10px !important;
    transform: translate(0) !important;
  }

  .fotterhatao+.hataofooter {
    display: none;
  }

  .Main-all-project .accordion_right_content_box .inputbox {
    bottom: 0;
  }

  .d-fex-center {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 100px auto;
    background: black;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 380px) {
  footer p {
    font-size: 10px;
  }

  .gallery_box .gallery_video {
    width: 100%;
  }

  .gallery_box .gallery-img {
    width: 100%;
    height: 40vw;
  }

  .taking_Test .main-logo {
    font-size: 16px;
  }

  .taking_Test .sub-title {
    font-size: 16px;
  }

  .d-fex-center {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 100px auto;
    background: black;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width:575px) {
  .participent-modalbox .modal {
    top: 28px;
  }

  .participent-modalbox .head-inpu,
  .participent-modalbox .search_btn {
    height: 45px;
  }

  .participent-modalbox ul {
    padding-left: 4px;
  }

  .participent-modalbox .Serachbar {
    margin-bottom: 20px;
  }

  .participent-modalbox label {
    margin-bottom: 4px;
  }
}

@media (max-width:800px) {
  .dragHandle.item {
    width: 800px;
  }

  .ov-auto {
    overflow: auto;
  }

  .table {
    margin-bottom: 0 !important;
  }
}

@media (max-width:767px) {
  .allInputsGroup .col-md-3 {
    max-width: 49%;
    flex: 0 0 49%;
    margin: 1px;
  }

  .addDel input.col-md-2 {
    padding: 8px;
    margin-bottom: 6px;
  }

  .addDel input.col-md-5 {
    margin-bottom: 6px;
  }

  .allInputs {
    margin-bottom: 0 !important;
  }

  .addDel select#exampleFormControlSelect2 {
    padding: 8px;
    margin-bottom: 6px;
  }

  .form-group.row.d-flex.justify-content-around.addDel {
    padding: 5px;
  }

  .marginTopChild {
    margin-top: 5px !important;
  }

  .addDel input.col-md-4 {
    height: 44px;
    margin-bottom: 5px;
  }
}