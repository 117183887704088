.frofile-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50px;
    object-position: top center;
}

.border1 {
    border: 1px solid #B402BA !important;
    background: #B402BA !important;
}

.badge-pill {
    padding: 0;
    width: 22px;
    height: 22px;
}

.border-right {
    border-right: 1px solid rgb(133 133 133) !important;
}

.card {
    /* width: 44%; */
    border: none;
    box-shadow: 0px 1px 4px 1px #ddd;
    border-radius: 0;
    margin: 3px 0;
}

.hedding-date {
    background: #b402ba;
    margin: 25px 0;
    padding: 10px;
    color: #fff;
    font-size: 16px;
}

.card .card-body h5>a {
    color: #B402BA;
}

.card .card-body p {
    margin-left: 66px;
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 0;
}

.card .card-body {
    padding: 10px;
}

.no-gutters {
    line-height: normal;
    padding: 0;
}

.card .card-body h5 {
    font-size: 16px;
    font-weight: bold;
}

.main-heddiang {
    font-weight: bold;
}

@media (max-width: 991px) {
    .main-heddiang {
        font-size: 26px;
    }

    .float-right {
        margin-bottom: 3px;
    }
}