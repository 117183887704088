* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.main_Container_fade {
  width: 100%;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background-color: #fbf4fc;
  margin: auto;
}

.main_Container {
  width: 100%;
  position: relative;
}

main {
  display: block;
}

select.form-select.form-select-lg.selectSection {
  margin: auto;
}


.labelSqure [type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}


.labelCircle [type=radio] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.labelCircle [type=radio]+img {
  cursor: pointer;
  width: 78px;
  height: 59px;
}

/* IMAGE STYLES */
[type=radio]+img {
  cursor: pointer;
  width: 78px;
  height: 59px;
}

/* CHECKED STYLES */
[type=radio]:checked+img {
  outline: 3px solid #b402ba;
}

.labelCircle [type=radio]:checked+img {
  outline: 3px solid #b402ba;
  border-radius: 50%;
}

.squareRadioButton {
  text-align: center;
  margin: 30px 0 30px 0;
  display: flex;
  justify-content: center;
}


.labelSqure {
  margin: 10px 20px;
}


label {
  display: inline-block;
  margin-bottom: .5rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.tds-shell {
  --tds-shell-content-height: calc(100vh - var(--tds-shell-header-height) - var(--tds-shell-footer-height));
  display: grid;
  grid-gap: 0 24px;
  grid-template-columns: auto;
  grid-template-rows: var(--tds-shell-header-height) 1fr var(--tds-shell-footer-height);
  min-block-size: 100vh;
  min-block-size: "-webkit-fill-available";
  grid-template-areas: "tds-shell-masthead""tds-shell-content""tds-shell-footer";
}

/* .right-scroll {
  height: calc(100vh - 390px);
  overflow-y: scroll;
} */

.right-scroll {
  height: calc(100vh - 140px);
  overflow-y: scroll;
  cursor: pointer;
}

#photoBooth .product-inner-area {
  display: block;
}

.openairMenu_Type {
  overflow-x: scroll;
}

.right-scroll::-webkit-scrollbar,
.openairMenu_Type::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  visibility: hidden;
  display: none;
}


.backdrop_4x6 {
  overflow-x: scroll;
  cursor: pointer;
}

img.rightSide-image-section {
  width: 100%;
}

img.rightSide-image-section.Red_CarptetImage {
  z-index: 1;
}

#photoBooth .form-check {
  width: 50%;
}

#photoBooth label.form-check-label {
  display: block;
}

#photoBooth .form-check-input {
  position: static;
}

.d-flex.mt-2.mb-2 {
  margin-left: 26px;
}


.template_design_change_twoBySix {
  width: 180px;
  height: 98px;
  position: absolute;
  z-index: 1;
  left: 226px;
  top: 23px;
  text-align: center;
  background-color: transparent;
}

p.title_BoothType.pop_up.title_Occasion.active_carpet {
  background-color: #b402ba;
  color: #000;
}

p.title_BoothType.pop_up.title_Occasion.active_Choose_Design {
  background-color: #b402ba;
  color: rgb(233, 233, 233);
}

.title_section.li_style.active {
  background-color: #b402ba;
  color: #fff;
}

p.title_BoothType.pop_up.title_Occasion.inActive_carpet {
  background-color: #eee;
  color: #000 !important;
  width: 100%;

}

.tabs_Compo .PrintSize_ButtonSection .width_changeDesign {
  padding: 10px 50px;
  margin-left: 18px;
  margin-right: 18px;
  border-radius: 6px;
}

.PrintSize_ButtonSection .pagination_prevAnd_Next {
  font-size: 20px;
}


p.title_BoothType.pop_up.title_Occasion.inActive_Choose_Design {
  background-color: #eee;
  color: #000;
  width: 100%;

}

.include_backdropColor_section.save_disable_back {
  background-color: #eee !important;
  color: #000 !important;
}


.user_Design_list_table {
  background: #b402ba30;
  margin-top: 30px;
  cursor: pointer;
}

.user_Design_list_table.th {
  background: #000;
  color: #fff;
}

.head_table {
  background: #b402ba;
  color: rgb(255, 255, 255);
  text-align: center;
}


#photoBooth .product-detail-all {
  /* height: calc(100vh - 300px); */
  overflow: hidden;
  padding-bottom: 0;
}

#photoBooth .right-scroll {
  height: 100vh;
  overflow-y: scroll;
}

.mid_section.twobySix {
  position: absolute;
  width: 30% !important;
  left: 35%;
  z-index: 1;
  /* top: 0%; */
}



.mid_section.fourbySix {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.mid_section.subject_image {
  position: absolute;
  width: 30% !important;
  left: 35%;
  z-index: 2;
  /* top: 0;
  left: 0; */
}


.mid_section.subject_imageFour {
  position: absolute;
  z-index: 4;
  left: 0;
  width: 100% !important;
  top: 0;
}

.mid_section.twoSix {
  position: absolute;
  width: 30% !important;
  left: 35%;
  /* top: 0;
  left: 0; */
}

.image_360 {
  height: 70vh;
  width: 100% !important;
}

.templateSection {
  z-index: 1;
}


.mid_section.fourSix {
  position: absolute;
  /* z-index: 2; */
}


.mid_section.fourSix.backdrop {
  z-index: 3;
}


#photoBooth .mid_section.twoBySix {
  width: 30% !important;
}

.left-scroll .second-row-images-box .fourBySixBack {
  height: auto !important;
}

#photoBooth img.rightSide-image-section {
  width: 100% !important;
  margin: 0 -18px;
  display: flex;
  /* background-color: #fbf4fc; */
}

#photoBooth img.mid_section {
  width: 60%;
  margin: 0 auto;
  display: flex;
  /* background-color: #fbf4fc; */
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* #track {
  width: 100%;
  transition: all 0.3s ease;
  overflow: hidden;
} */

.title_BoothType {
  text-align: center;
  padding: 11px;
  font-size: 16px;
  font-weight: 800;
}

.title_section {
  width: 50%;
  margin: auto;
  background-color: #fff;
  text-align: center;
  margin-top: 40px;
}


/* ----------------------------------------------------- */

.OpenAirRadio_Section {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 84px; */
}

.labelCircle [type=radio]+img {
  width: 50px;
  height: 50px;
  border: 3px solid #e74c3a;
  border-radius: 50%;
}

#modelPhotoBooth.modal-dialog {
  margin-top: 250px;
}


.white {
  background-color: white;
  cursor: pointer;
}

.black {
  background-color: black;
  cursor: pointer;
}

.none {
  cursor: pointer;
}

.pop_up {
  padding: 0px 10px;
  line-height: 30px;
  font-size: 14px;
  align-self: center;
  margin-right: 10px;
  box-shadow: none;
  border: none;
  background-color: #c3bdbd;

}

.label_title {
  text-align: center;
}

.labelCircle [type=radio] img {
  border: 2px solid #e74c3a;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.labelCircle [type=radio]:checked+img {
  border: 3px solid #b402ba;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.labelCircle {
  margin: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* border: 3px solid #e74c3a; */
  overflow: hidden;
}

.label_title {
  text-align: center;
  position: relative;
}

p.title_Occasion {
  font-size: 14px;
  font-weight: 700;
  color: gray;
  text-align: center;
}

p.title_Occasion b {
  font-size: 18px;
  color: #000;
}

.add_remove_update_background .title_Occasion {
  background: #b402ba;
  padding: 5px;
  color: #fff !important;
}

p.tabTitle {
  font-size: 16px;
  font-weight: 600;
}

.Main_details_section {
  margin-bottom: 10px;
  position: relative;
}

.Main_details_section_fourBySix {
  margin-bottom: 10px;
  position: relative;
}

.title_booth_main_section {
  display: flex;
  align-items: center;
  justify-content: center
}

.title_booth_main_section select {
  margin-right: 20px;
}

.title_booth_type {
  text-align: center;
}

.labelCircle.none.red_line {
  position: relative;
  border: 1px solid #000;
}

.labelCircle.none.red_line::before {
  content: "";
  position: absolute;
  width: 6%;
  height: 100%;
  background: red;
  z-index: 1;
  transform: rotate(45deg);
}

#photoBooth .form-select {
  background-color: #eee !important;
  width: 45%;
  margin: 0;
  margin-right: 20px;
  padding: 10px 20px 10px 10px;
  font-size: 16px;
  border: 0;
}

.title_booth_btn {
  border: 0;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16p
}

.templateDisplayFlex_Section {
  display: none !important;
}

.ChangeDesign_button {
  background-color: #eee;
  color: #000;
  font-size: 16px;
  padding: 16px 30px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  padding: 5px 10px;
  margin: 10px;
}

.PrintSize_ButtonSection {
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
}

.Slider-Wrapper {
  width: 80%;
  margin: auto;
}

.slick-prev:before, .slick-next:before {
  font-size: 32px !important;
  color: #b402ba !important;
}

.ChangeDesign_button:hover {
  background-color: #b402ba;
  color: #fff;
  margin: auto;
}

.title_booth_btn_active {
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #b402ba;
  color: #fff;
  font-size: 16px
}

.title_section {
  width: 40%;
  margin: 20px auto;
  background-color: #fff;
  text-align: center;
  margin-top: 40px;
  height: auto !important;
  border-radius: 4px;
}

.title_BoothType.pop_up {
  padding: 6px;
  font-size: 16px;
  border-radius: 4px;
  background: #b402ba;
  width: 100%;
  margin: 8px auto;
  color: #ffffff
}

.include_backdropColor_section {
  margin-top: 25px;
}

.bg_color {
  background-color: white;
}

.d_flex {
  /* display: flex; */
  position: absolute;
}

/* p.Arrow_Prev_paragraph {
  position: absolute;
  z-index: 1;
  left: -10px;
  top: 38%;
  transform: translate(-10%, -38%);
  margin: 0;
  width: 24px;
  height: 24px;
  background: #b402ba;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.Upload_fileRef {
  position: relative;
}

.fileRef_Input {
  margin: -44px 0 0 0;
  background-color: rgb(238, 238, 238);
  color: rgb(0, 0, 0);
  left: 0px;
  opacity: 0;
  right: 0;
  position: absolute;
}


.DownloadButton {
  width: 100%;
  background-color: #b402ba;
  border: none;
  color: white;
  font-weight: bold;
  padding: 6px;
  cursor: pointer;
}

.DownloadButton_Disable {
  width: 100%;
  background-color: #eee;
  border: none;
  color: #000;
  font-weight: bold;
  padding: 6px;
  cursor: pointer;

}


/* modal */

/* #modal_section .img-size {
  height: 450px;
  width: 700px;
  background-size: cover;
  overflow: hidden;
}

#modal_section .modal-content {
  width: 700px;
  border: none;
}

#modal_section .modal-body {
  padding: 0;
} */

/* #modal_section .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23009be1' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  width: 30px;
  height: 48px;
}

#modal_section .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23009be1' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  width: 30px;
  height: 48px;
}

#photoBooth .modal {
  background: rgba(0, 0, 0, 0.7);
} */

/* .Slider_content {
  position: relative;
  text-align: center;
  height: 60vh;
  width: 100%;
} */


/* .slick-slider {
  position: initial !important;
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 40px !important;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-next {
  right: -34px !important;
}

.slick-prev {
  left: -55px !important;
}


.modal-header {
  border-bottom: none !important;
  border-top-left-radius: none !important;
  border-top-right-radius: none !important;
} */

.li_style {
  list-style: none;
}

li.title_section.li_style {
  margin: 4px;
}

/* .activelearn {
  border: 1px solid red;
} */

/* .title_BoothType-section.active */

.title_BoothType-section {
  /* background: #fff; */
  padding: 5px;
  margin: 0px;
  display: block;
  border-radius: 4px;
  color: #000;
}

.img-round-title {
  background: #eee;
  text-align: center;
}

.add_remove_list {
  background-color: #eee;
}

.two_four_section {
  justify-content: center;
}

.occasionSelect {
  width: 100% !important;
}

.title_Occasion_title {
  padding: 6px;
  font-size: 16px;
  border-radius: 4px;
  background: #b402ba;
  width: 50%;
  margin: 8px auto;
  color: #ffffff;
  text-align: center;
}

/* div {
  transform: none !important;
} */

.OpenAirRadio_Section_checkbox {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 84px;
}

.label_title p {
  /* padding: 0 13px; */
  font-size: 14px;
  text-align: center;
}

.label_title_checkbox {
  text-align: center;
  position: relative;
  text-align: center;
}

.label_title_checkbox p {
  position: absolute;
  bottom: -40px;
  width: 100%;
}

.labelCircle_checkbox [type=checkbox] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.labelCircle_checkbox [type=checkbox]+img {
  cursor: pointer;
  width: 78px;
  height: 59px;
}

.labelCircle_checkbox [type=checkbox]:checked+img {
  outline: 3px solid #b402ba;
  border-radius: 50%;
}

[type=checkbox]:checked+img {
  outline: 3px solid #b402ba;
}

.labelCircle_checkbox {
  margin: 10px 20px;
}

.labelCircle_checkbox {
  margin: 10px 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.labelCircle_checkbox [type=checkbox]+img {
  width: 100%;
  height: auto;
}

.labelCircle_checkbox [type=checkbox]:checked+img {
  border: 2px solid #b402ba;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.labelCircle_checkbox {
  margin: 10px 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

p.titleSection_details {
  margin: 0 0 0 28px;
}

.threeSixty-inputSection {
  width: 100%;
  height: 56px;
  border: 1px solid rgb(180, 2, 186);
}

.title_section_ledInlfatable {
  display: flex;
  margin-right: 10px;
  text-align: center;
  justify-content: start;
  margin-top: 40px;
}

button.title_section.li_style.active.m-0 {
  border: none;
}

.slick-prev, .slick-next {
  top: 30px !important;
}

.template_design_change {
  background-color: transparent;
  text-align: center;
  position: absolute;
  z-index: 1;
  width: 277px;
  height: 172px;
  left: 37px;
  top: 36px;
}


.ChangeDesign_titleChange_Default {
  font-size: 30px;
  font-family: 'Dancing Script', 'cursive';
  font-weight: bolder;
  color: black;
}

.ChangeDesign_titleChange_Default_twoBySix {
  font-size: 20px;
  font-family: 'Dancing Script', 'cursive';
  font-weight: bolder;
  color: black;
}

.ChangeDesign_occationChooseDesign_Default_twoBySix {
  font-size: 20px;
  font-family: 'Dancing Script', 'cursive';
  font-weight: bolder;
  color: black
}

.ChangeDesign_dateChange_Default_twoBySix {
  font-size: 13px;
  font-family: 'Didact Gothic', 'sans-serif';
  font-weight: 700;
  color: black;
  margin-left: 0px;
}

.ChangeDesign_occationChooseDesign_Default {
  font-size: 35px;
  font-family: 'Dancing Script', 'cursive';
  font-weight: bolder;
  color: black;
  margin-left: 29px;
}

.ChangeDesign_dateChange_Default {
  font-size: 22px;
  font-family: 'Didact Gothic', 'sans-serif';
  font-weight: 700;
  color: black;
  margin-left: 0px;
}

#exampleModal span {
  color: #000;
  font-size: 16px;
  font-weight: bold;
}

p.modal_title {
  font-size: 15px;
  color: #000;
  font-weight: 700;
}

.rightSide-image-section {
  position: absolute;
  top: 0;
}

img.rightSide-image-section.PropTableImage {
  z-index: 2;
}

img.rightSide-image-section.KioskOpenAirImage {
  z-index: 3;
}

.rightSide-image-section {
  position: absolute;
  width: 490px !important;
  top: auto;
  left: 0;
}

/* .right_side_image_section {
  display: flex;
  align-items: center;
} */

#photoBooth img.mid_section {
  width: 100%;
}

img.rightSide-image-section.photoBooth_area {
  z-index: 0;
}

.second-row-images-box {
  height: auto;
  margin: 0;
}


.Tempprev_Paragraph {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0px, -50%);
  margin: 0;
  cursor: pointer;
  z-index: 11;
}

.TempNext_Paragraph {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0px, -50%);
  margin: 0;
  cursor: pointer;
  z-index: 11;
}





/* .second-row-images-box img {
  height: 100%;
} */


.upload_Image {
  left: 53%;
  top: 9%;
  position: absolute;
  height: auto !important;
  max-width: 245px;
  z-index: 2;
}

.upload_Image_1 {
  left: 53%;
  top: 47%;
  position: absolute;
  height: auto !important;
  max-width: 245px;
  z-index: 2;
}

.upload_Image_3 {
  left: 8%;
  top: 47%;
  position: absolute;
  height: auto !important;
  max-width: 245px;
  z-index: 2;
}


.images-one img,
.image-two img {
  width: 150px !important;
  height: 150px !important;
  object-fit: contain;
}


.image-two {
  position: absolute;
  width: 100%;
  display: flex;
  bottom: 35px;
  justify-content: space-evenly;
}


.image_Wraaper {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 414px;
}

.images-one {
  position: absolute;
  right: 16%;
  top: 40px;
}

.memoryBook {
  width: 100%;
}

button.Upload_btn {
  position: absolute;
  z-index: 2;
  color: #000;
  left: 123px;
  padding-top: 8px;
}

.colorOutput {
  border: 1px solid #fff;
  left: 18px;
  height: 59px;
  top: 7px;
}

.colorSlider {
  left: 18px;
}

/* ----------------------------------------------------------------------------------------------------- */


p.imglist {
  display: flex;
  width: 100%;
}

.image_title {
  width: 180px;
  margin-left: 10px;
}

ul.tui-image-editor-help-menu.left {
  display: none !important;
}

.titleName {
  color: #b2a3a3;
  font-size: 13px;
  text-align: center;
}

.scroll {
  width: 1011px;
  overflow: auto;
  cursor: pointer;
}



.sc-3ln9py-0.lUMCm.FIE_canvas-container {
  height: 90vh !important;
}

.sc-dwg14p-2.cHPFPE.FIE_rect-tool-button {
  display: none !important;
}

.sc-dwg14p-2.cHPFPE.FIE_rect-tool-button {
  display: none !important;
}

.sc-dwg14p-2.cHPFPE.FIE_ellipse-tool-button {
  display: none !important;
}

.sc-dwg14p-2.cHPFPE.FIE_polygon-tool-button {
  display: none !important;
}

.sc-dwg14p-2.cHPFPE.FIE_pen-tool-button {
  display: none !important;
}

.sc-dwg14p-2.cHPFPE.FIE_line-tool-button {
  display: none !important;
}

.sc-dwg14p-2.cHPFPE.FIE_arrow-tool-button {
  display: none !important;
}

.sc-qvjmv1-6.bARUxi.FIE_annotation-option-triggerer {
  display: none !important;
}

.sc-21g986-5.iTooxF.FIE_topbar-center-options {
  display: none !important;
}

button.sc-lxwit0-2.jIgSWS.sc-m9ezm7-1.yXTYd.FIE_topbar-save-button.SfxButton-root {
  background-color: #b402ba;
  padding: 15px !important;
}

button.sc-lxwit0-2.sc-10edh45-0.bqChvH.sc-21g986-2.cuEdAt.FIE_topbar-reset-button.SfxButton-root.SfxIconButton-root {
  color: #b402ba !important;
}

button.sc-lxwit0-2.sc-10edh45-0.bqChvH.sc-21g986-2.cuEdAt.FIE_topbar-undo-button.SfxButton-root.SfxIconButton-root {
  color: #b402ba !important;
}

button.sc-lxwit0-2.sc-10edh45-0.bqChvH.sc-21g986-2.cuEdAt.FIE_topbar-redo-button.SfxButton-root.SfxIconButton-root {
  color: #b402ba !important;
}

button.sc-lxwit0-2.fChmtv.SfxButton-root {
  background-color: #b402ba !important;
}

/* svg {
    color: #b402ba !important;
} */

.sc-qvjmv1-6.bARUxi.FIE_text-bold-option svg {
  color: #b402ba !important;
  /* background-color: #b402ba !important;  */
}

button.sc-lxwit0-2.jIgSWS.SfxButton-root {
  background-color: #b402ba !important;
}

.sc-21g986-13.bwSSmU.FIE_save-resize-wrapper {
  display: none !important;
}

button.sc-lxwit0-2.khuzkx.FIE_image-tool-add-option.SfxButton-root {
  background-color: #b402ba !important;
  color: #ffff !important;
  padding: 15px !important;
}

.cHPFPE[aria-selected="true"] {
  background: #eec7f0 !important;
  color: #fff !important;
}

label.sc-16k2ql4-0.jMSaZu.sc-dwg14p-3.iSxCsO.SfxLabel-root {
  color: black !important;
  font-size: 14px !important;
  font-weight: 800 !important;
}

.sc-ugtqr7-0.ebWdsB.FIE_root {
  width: 1054px !important;
  max-width: 96% !important;
}

.sc-dwg14p-0.eDZmnj.FIE_tools-bar-wrapper {
  margin-bottom: 28px !important;
}

/* .GrNext {
  position: absolute;
  z-index: 1;
} */

.Main-all-project .container .product-detail-all .product-inner-area svg {
  right: 0 !important;
  top: none !important;
}


.pagination_prevAnd_Next {
  font-size: 32px;
}



.Choose_Design_title {
  /* width: 80%; */
  border: none;
  background-color: #eee;
  margin: 7px 0 14px 0;
  padding: 6px;

}

.Choose_Design {
  color: rgb(79, 76, 76);
}

.Choose_Design_title::placeholder {
  color: #000000;
  font-style: italic;
  padding-left: 7px;
}

.MdOutlineColorLens_icon {
  font-size: 35px;
  text-align: center;
  margin: 5px 0 0 0;
}

@media screen and (max-width: 1199px) {
  .sc-3ln9py-0.lUMCm.FIE_canvas-container {
    height: 50vh !important;
    width: 95vw !important;
  }

  .scroll,
  .sc-ugtqr7-0.ebWdsB.FIE_root {
    width: 95vw !important;
  }
}

@media screen and (max-width: 575px) {

  .sc-3ln9py-0.lUMCm.FIE_canvas-container,
  .scroll,
  .sc-ugtqr7-0.ebWdsB.FIE_root {
    width: 85vw !important;
  }

  .sc-dwg14p-1 {
    margin: 0 0 0 15px !important;
  }

  .sc-21g986-0 {
    padding: 20px 6px 4px 6px !important;
  }

  .sc-dwg14p-0 {
    padding: 20px !important;
  }

  .title_booth_main_section {
    flex-wrap: wrap;
  }

  #photoBooth .form-select {
    width: 100%;
    margin: 0;
  }

  button.title_booth_btn {
    width: 100%;
    margin: 10px 0;
  }
}

/* span.sc-16k2ql4-1.kdECGg.SfxLabel-text {
  color: red;
} */

.heading_chooseBackdrop {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

.heading_chooseBackdrop_print {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
}

.radioButton {
  text-align: center;
}

.printSize {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.css-dmmspl-MuiFormGroup-root {
  display: block !important;
  justify-content: center !important;
  text-align: center;
}

.MuiFormGroup-root.displayFlex.css-dmmspl-MuiFormGroup-root {
  margin-left: 457px;
  margin-top: 20px;
}

#photoBooth .product-inner-area .FIE_root .FIE_topbar {
  flex-direction: row-reverse;
}

#photoBooth .product-inner-area .FIE_root .FIE_main-container {
  flex-direction: row-reverse;
}

#photoBooth .FIE_root .FIE_text-tool-options .FIE_annotation-option-triggerer {
  display: none;
}

#photoBooth .FIE_rect-tool-button,
#photoBooth .FIE_ellipse-tool-button,
#photoBooth .FIE_polygon-tool-button,
#photoBooth .FIE_pen-tool-button,
#photoBooth .FIE_line-tool-button,
#photoBooth .FIE_arrow-tool-button {
  display: none;
}

.sc-dwg14p-0.eyimOZ.FIE_tools-bar-wrapper {
  margin-right: 0;
  position: absolute;
  top: 189px;
  right: 53px;
}

.sc-qvjmv1-6.brCuAs.FIE_text-bold-option {
  display: none;
}

.sc-qvjmv1-6.brCuAs.FIE_text-italic-option {
  display: none;
}

.sc-21g986-5.pzLjy.FIE_topbar-center-options {
  display: none !important;
}

#photoBooth .FIE_editor-content .FIE_tools-bar-wrapper {
  position: absolute;
  bottom: 30px;
  right: 0;
}





#photoBooth .modal-header {
  border-bottom: 0;
}

#photoBooth .opem_title {
  font-size: 26px;
  font-weight: bold;
  color: #000;
  letter-spacing: 1.5px;
  margin: -25px 0 20px;
}

#exampleModal .text {
  font-weight: 400;
  font-size: 14px;
}

#photoBooth .modal-content {
  border-radius: 30px;
}

#photoBooth .modal-content {
  border-radius: 30px;
  padding: 0 20px;
}

.pop_modal_data p {
  margin: 0;
}

.pop_modal_data .modal_title {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  letter-spacing: 1.1px;
  margin-bottom: 15px;
}

.customer_details:hover {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 4px;
  cursor: pointer;
}


.selectBoothType:focus {
  box-shadow: none;
  border: 2px solid #b402ba;
}

.include_backdropColor_section input[type="range"] {
  font-size: 7px !important;
  background: transparent;
  cursor: pointer;
}

/* ---------------------------------------------------------------------------------------------------------- */


@media(max-width:1200px) {
  .right_side_image_section {
    height: 400px;
  }

  #photoBooth img.rightSide-image-section {
    right: 0;
    left: 0;
  }

  .images-one {
    position: absolute;
    right: 10%;
    top: 5px;
  }

  .image_Wraaper {
    height: 292px;
  }

  .images-one img,
  .image-two img {
    width: 120px !important;
    height: 120px !important;
    object-fit: contain;
  }

  .images-one {
    position: absolute;
    right: 10%;
    top: 20px;
  }

  .image-two {
    bottom: 18px;
  }
}

@media(max-width:991px) {
  /* img.mid_section.fourSix {
    height: 292px;
  }

  .subjectImage4by6 {
    height: 291px;
  } */

}

@media(max-width:767px) {
  #photoBooth .modal-dialog {
    max-width: 96%
  }

  #photoBooth .modal-content {
    border-radius: 6px;
    padding: 0px;
  }

  #photoBooth .opem_title {
    font-size: 18px;
    margin: 0px 0 10px;
  }

  .pop_modal_data .modal_title {
    font-size: 16px;
    margin-bottom: 10px;
  }

  #photoBooth .right-scroll {
    height: auto;
    overflow-y: auto;
  }

  .Main_details_section_fourBySix {
    z-index: 1;
    margin: 15px;
  }

  .main_Container.left-scroll.col-xl-7.col-md-6.col-12.second-box-details {
    height: 250px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  /* #photoBooth img.mid_section {
    height: 100% !important;
  } */

  .second-row-images-box {
    height: 100%;
    margin: 0;
    width: 100%;
  }

  #photoBooth img.rightSide-image-section {
    margin: auto;
    height: 100%;
  }

  .images-one {
    position: absolute;
    right: 16%;
    top: 20px;
  }

  #photoBooth img.rightSide-image-section {
    width: 400px !important;
  }


  .main_Container.left-scroll.col-xl-7.col-md-6.col-12.second-box-details {
    flex-direction: column;
    height: auto;
  }

}

@media(max-width:575px) {
  .images-one img, .image-two img {
    width: 100px !important;
    height: 100px !important;
  }

  .images-one {
    position: absolute;
    right: 10%;
    top: 28px;
  }

  .image-two {
    bottom: 30px;
  }
}






@media(max-width:400px) {
  .right_side_image_section {
    height: 250px;
  }

  #photoBooth img.rightSide-image-section {
    width: 100% !important;
  }
}


@media(min-width:768px) {
  #photoBooth .modal-dialog {
    max-width: 720px;
    margin: 1.75rem auto;
  }

  #photoBooth .modal-content {
    border-radius: 10px;
    padding: 0px 10px;
  }
}

@media(min-width:992px) {
  #photoBooth .modal-lg, #photoBooth .modal-xl {
    max-width: 960px;
  }
}