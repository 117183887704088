.profile_img-are {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    position: relative;
}

.profile_img-are label {
    margin: 0;
}

.profile_img-are .hovermain{
    position: relative;
}


.profile_img-are button {
    margin-top: 20px;
    padding: 0 10px;
    border: 2px solid #aaa;
}

.profile_img-are .hovermain  {
    content: '';
    opacity: 0;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #000;
    position: absolute;
    z-index: 1;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -60%);
}

.profile_img-are:hover::after  {
    opacity: 1;
}

.profile_img-are .profile_img_round{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;

}
.profile_img-are .profile_img_round img{
    width:100%;
    height: 100%;
    transition: 0.2s all ease-in-out;
    object-fit: cover;
}
.profile_img-are .profile_img_round .edit_halwa{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75px;
    height: 75px;
  
}
.profile_img-are .profile_img_round .edit_halwa img{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.4s
    
}
.profile_img-are .profile_img_round:hover > img{
    filter: blur(2px);
}
.profile_img-are .profile_img_round:hover .edit_halwa img{
    opacity: 1;
    cursor: pointer;
}

.error_message {
    color: red;
    margin-top: 10px;
    font-size: 10px;
    position: absolute;
    left: 15px;
    bottom: 0px;
    font-weight: 600;
}

.limit{
    max-lines: 12ch;
}