.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.puriScreen {
  width: 100vw;
  height: 100vh;
}
.box {
  position: absolute;
  cursor: move;
  color: black;
  max-width: 215px;
  border-radius: 5px;
  padding: 1em;
  margin: auto;
  user-select: none;
}

.boxs {
  position: absolute;
  cursor: move;
  color: black;
  max-width: 215px;
  border-radius: 5px;
  padding: 1em;
  margin: auto;
  user-select: none;
}

.main-bada-box {
  width: 100%;
  display: flex;
}

.bada_box {
  width: 100%;
  margin-left: auto;
} 

.box_header {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.Happy {
  width: 100%;
}

.main-logo{
  color: white;
    font-size: 34px;
    text-transform: uppercase;
    font-family: 'arial';
    border: 6px solid #fff;
    border-radius: 12px;
}
.sub-title{
  margin: 0;
}

.teenvala {
  display: flex;
  width: 100%;
  margin: auto;
}

.dusravala {
  width: 50%;
  background: #442960;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.pehlaval {
  width: 25%;
}

.imagekiWidth {
  width: 100%;
  height: 100%;
}

.thought {
  font-size: 1.3rem;
  color: white;
  font-weight: bold;
  text-align: center;
  font-family: math;   
   margin: 0;

}

.birthday_Name {
  text-align: center;
  color: white;
   margin: 0;
  font-family: ui-monospace;
}

.BirthdayName {
  font-size: 2.5rem;
  font-family: inherit;
  margin: 0;

}

.KuchBHi_InputVALA input {
  padding: 5px;
  width: 30%;
  border: 1px solid #ddd;
}

.KuchBHi_InputVALA button {
  padding: 5px;
  border: 1px solid #b402ba;
  background: #b402ba;
  color: #fff;
}



.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background: transparent;
  border: 2px solid #b402ba;
  text-align: center;
}



.crackerpic{
  width: 100%;
  height: 160px;
  object-fit: contain;
}

.Template_size {
  width: 100%;
  height: 50px;
  object-fit: contain;
}

.InputFieldTemp{
  width:100%;
  margin: 5px;
}

/* body {
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
} */

.handle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #2662c1;
  color: #fff;
  cursor: move;
  text-align: center;
}

.handle:not(.react-draggable-dragging) {
  -webkit-transition: -webkit-transform 0.5s ease-out; /* Safari */
  transition: transform 0.5s ease-out;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* temp2 */

.bg-img img{
  height: auto;
  width: 100%;
}
.bg-img{
  position: relative;
}

.ms-title {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  padding: 1.300vw 0;
  display: flex;
  margin-left: 5%;
  align-items: center;
  width:70%;
  flex-wrap: wrap;
}
.taking_Test .main-logo {
  text-align: center;
  font-size: 50px;
  text-transform: uppercase;
  font-family: 'arial';
  width: 60%;
  border: 10px solid #fff;
  border-radius: 12px;
  margin: 0 auto;

}
.ms-title  .sub-title {
  max-width: 22.05vw;
  font-size: 1.427083vw;
  text-transform: uppercase;
  color: #ffffff;
  font-family: 'opensans';
  word-wrap: break-word;
  padding: 0.90166vw 0;
  border: 5px solid transparent;
  width: 100%;
}

.taking_Test .sub-title{
  width: 60%;
  margin: 0 auto;
  text-align: center;
  font-size: 30px;
  letter-spacing: 5px;
  text-transform: capitalize;
}

.taking_Test {
  position: absolute;
  top: 16%;
  left: -44px;
  width: 90%;
  color: #ffffff;
}


@media (max-width: 575px) {
  .thought {
    font-size: 14px;
  }
  .BirthdayName {
    font-size: 25px;
    margin: 10px 0 0;
  }
  .crackerpic {
    height: 100px;
  }
  .KuchBHi_InputVALA input {
    width: 40%;
    font-size: 14px;
  }
  .KuchBHi_InputVALA button {
      font-size: 14px;
  }
  .taking_Test .main-logo {
    font-size: 20px;
    border: 2px solid #fff;
    padding: 5px;
  }
  .taking_Test .sub-title { 
    font-size: 22px;
    margin: 15px auto 0;
    letter-spacing: 0px;
  }
  .KuchBHi_InputVALA {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ms-title  .sub-title {
    max-width: 100%;
    font-size: 16px;
  }
}